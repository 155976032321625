<template>
  <div class="container-kiosk">
    <div class="container-body-kiosk min-container-kiosk text-center">
          <img src="@/assets/img/spinner.png" alt="" class="spinner-load " >
      </div>
  </div>
</template>

<script>
export default {
}
</script>
